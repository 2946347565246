<template v-slot:no-data>
	<div>
		<v-container style="display: contents;">
			<v-row>
				<div class="col-md-12 col-sm-12">
					<!--v-if="TypeAreaAssignPerson == TypeAreaGeneric"-->
					<v-card dense >
						<v-row  style="margin: auto;">
							<v-col>
								<s-select
									autocomplete
									full
									:items="itemsCaliber"
									item-value="TypePallet"
									item-text="TypePalletName"
									label="Calibres"
									return-object
									v-model="itemPallet.CaliberObj">									
								</s-select>
							</v-col>
							<v-col>
								<s-select
									:items="ItemsNumberGirdle"
									v-model="itemPallet.NumberGirdle"									
									label="NUmero de Faja"/>
							</v-col>
							<v-col>
								<v-btn
									fab
									x-small
									color="success"
									@click="addCaliberPallet">
									<i class="fas fa-plus"></i>
								</v-btn>
							</v-col>
						</v-row>
						<v-divider ></v-divider>
						<v-row style="margin: auto;">		
							<v-col
								cols="6"
								lg="3"
								md="4"
								:key="col.TypePallet"
								v-for="col in itemsPallet"
							>
							<v-badge
								 color="white">
						 	<template v-slot:badge>							 
								<v-btn	
									 									
									style="margin-left: -29px;margin-top: 2px;"
									fab
									small
									color="white"
									@click="removeCaliberPallet(col)">
										<v-icon 
											color="error" 
											class="fas fa-trash"
											small>
										</v-icon>

								</v-btn>								 
							</template>
								<v-card >								 
									<v-card-title style="background: #eaeaea;">
										
										<span class="text-h6 font-weight-light"
											>{{ col.TypeCaliberName }}
											<!-- <v-chip :color="'info'">
												{{ col.TypePalletHelper }}
											</v-chip> -->
										</span>
										<span>-&nbsp;{{ col.TypeCultiveName}}</span>
										
									</v-card-title>
									<v-card-text style="display: grid; background: #eaeaea;"> 
										<v-img
											lazy-src="../../../assets/pallets44.png"
											max-height="200"
											max-width="200"
											src="../../../assets/pallets44.png"
											><v-btn
												style="border-radius: 100%; height: 44%; width: 30%; margin: auto; display: flex; justify-content: space-evenly; "
												color="warning"
												@click="openModal(col)"
											>
												<span class="white--text text-h4">{{
													col.LppQuantityJaba
												}}</span>
											</v-btn>
										</v-img>
										 {{ "N° Faja :" + col.NumberGirdle}}	

									</v-card-text>
									<v-card-actions
										>
										 
										<v-row style="margin-top: 0.1em;">
											<v-col cols="12">
												<s-select-definition
													:def="1455"
													style="border-right: groove;"
													v-model="col.TypeBsnLine"
												/>
											</v-col>
											<v-col cols="12">
												<s-select-definition
													:def="1181"
													style="border-right: groove;"
													v-model="col.TypeDestiny"
												/>
											</v-col>
									
											<v-col cols="12" v-show="col.TypeDestiny == 2">
												<s-select 
													:items="Scaffolds"
													itemValue="ScfId"
													itemText="ScfCode"
													style="border-right: groove;"
													v-model="col.ScfId"
												/>
											</v-col>
											<v-col cols="12">
												<v-btn
													outlined
													small
													elevation="3"
													color="success"
													style="width:100%"
													@click="clickSend(col)"
												>
													Enviar
												</v-btn>
											</v-col>
										</v-row>
							 		</v-card-actions>																
								</v-card>	
							</v-badge>
							</v-col>
						</v-row>
					</v-card>

					<v-card dense>
						
						<v-row style="margin: auto;">
							<v-col cols="12">
								<s-toolbar
									dark
									:color="'#BAB6B5'"
									class="mr-4 ml-2"
									style="display: flex"
									print
									view
									@print="printQR()"
									@view="view()"
								></s-toolbar>
								<v-data-table
									:items="itemsSend"
									item-key="LlpID"
									dense
									:headers="headerSend"
									:items-per-page="15"
									show-group-by
									:group-desc="true"
									disable-sort
									v-model="selectedItemsSend"
									@click:row="rowClickSend"
									
								>
									<template v-slot:item.Tranfer="props">
										<v-tooltip top allow-overflow color="primary">
											<!-- fab border-radius: 13px; fa-exchange-alt group-by="TypeDestinyName"-->
											<template v-slot:activator="{ on, attrs }">
												<v-btn
													x-small
													:color="'info'"
													fab
													style="margin: 6px 6px 6px 6px;"
													@click="clickTranfer(props.item)"
													v-bind="attrs"
													v-on="on"
													>
													<v-icon class="fas fa-arrow-circle-up"></v-icon>
												</v-btn>
											</template>
											<span>Transferir</span>
										</v-tooltip>
										
									</template>

									<template v-slot:item.delete="props">
												<v-btn
													x-small
													:color="'error'"
													fab
													style="margin: 6px 6px 6px 6px;"
													@click="deleteParihuela(props.item)"
													>
													<v-icon
															style="font-size: 16px !important"
															>fas fa-times</v-icon>
												</v-btn>
									</template>

									<template v-slot:item.RcfID="row">
										<v-chip
											small
											dark
											outlined
											:color="'red'">
											{{row.item.RcfID}}
										</v-chip>
									</template>	

								</v-data-table>
							</v-col>
						</v-row>
					</v-card>
				</div>
			</v-row>
		</v-container>

		<v-dialog
			v-if="openDialog"
			v-model="openDialog"
			persistent
			transition="dialog-bottom-transition"
			fullscreen
		>
			<supply-pallet 
			:nroPallet="PalletNro" 
			:typeCaliber="typeCaliber" 
			:typeCrop="TypeCrop" 
			:itemPalletOne="itemPalletOne" 
			:TypeCultive="TypeCultive"
			@close="close()">
			</supply-pallet>
		</v-dialog>

		<v-dialog
			v-if="openTranfer"
			v-model="openTranfer"
			persistent
			transition="dialog-bottom-transition"
		>
			<tranfer-pallet :value="itemsTranfer" @close="closeTransfer()">

			</tranfer-pallet>
		</v-dialog>

		<v-row justify="center" cols="12" style="display: none">
			<v-col cols="12" v-for="item in selectedItemsSend">
				<qr-code
					:text="
						item.LlpID +
							', Jabas: ' +
							item.LlpQuantityJaba +
							',' +
							item.TypeCaliberName +
							',' +
							item.TypeCultiveName +
							',' +
							item.VrtName +
							',' +
							item.TypeDestinyName +
							',' +
							item.TypeDestiny
					"
					error-level="H"
					hidden
				>
				</qr-code
				><br />
			</v-col>
		</v-row>

		<v-dialog
			v-if="openDialogIndex"
			v-model="openDialogIndex"
			persistent
			width="60%"
		>
			<supply-pallet-index @click="click($event)"></supply-pallet-index>
		</v-dialog>

		<v-dialog
			v-if="openViewQR"
			v-model="openViewQR"
			width="40%"
		>
			<prf-viewqr :selectedItemsSend="selectedItemsSend" @click="openViewQR = false"></prf-viewqr>
		</v-dialog>
	</div>
</template>
<script>
	//Service
	import _sLotProdCalibratedService from "../../../services/FreshProduction/PrfLotProductionCalibratedService";
	import _sLotProdCalibratedPrintQRService from "../../../services/FreshProduction/PrfLotProductionCalibratedQRService";
	import SupplyPallet from "./ReworkNew.vue";
	import TranferPallet from './TranferPallet.vue';
	import SupplyPalletIndex from './SupplyPalletIndex.vue';
	import PrfViewqr from './PrfViewqr.vue';
	import qrCode from "vue-qr-generator";
	import signalr from "signalr";
	import ServiceScaffold from '@/services/FreshProduction/ConfigFresh/ScaffoldService'
	import _sCreatePalletTempService from '@/services/FreshProduction/PrfCreatePalletTempService'
	import _sGenParam from "@/services/General/ParameterService";

    



	export default {
		components: { SupplyPallet, TranferPallet, qrCode, SupplyPalletIndex, PrfViewqr },
		data: () => ({
			Scaffolds: [],
			TypeCultive:0,
			itemsSendDetail: [],

			selectedItemsSend: [],

			itemsPallet: [],
			openDialog: false,
			openTranfer: false,
			openViewQR:false,
			PalletNro: 0,
			typeCaliber: 0,
			textQR: "",
			itemsSend: [],
			headerSend: [
				{ text: "Tranfer", value: "Tranfer" },
				{ text: "Borrar", value: "delete" },
				{ text: "Id", value: "LlpID" },
				{ text: "Lote Producción", value: "RcfID" },
				{ text: "Tarima", value: "ScfCode" },
				{ text: "N° Guia Cosecha", value: "RcfNumberHarvestGuide", width: 300 },
				{ text: "Destino", value: "TypeDestinyName" },
				{ text: "Calibre", value: "TypeCaliberName" },
				{ text: "Cant. Jabas", value: "LlpQuantityJaba" },
				{ text: "Tipo Cultivo", value: "TypeCropName" },
				{ text: "Cultivo", value: "TypeCultiveName" },
				{ text: "Variedad", value: "VrtName" },
				{ text: "Peso (KG)", value: "WeightAverageParihuela" },
				
			],
			itemsTranfer: {},
			executeQR: false,
			WbbNetWeight: 0,

			openDialogIndex: true,
			TypeCrop: 1,
			TypeAreaAssignPerson: 0,
			TypeAreaGeneric: 0,
			itemPalletOne: {},
			itemPallet: {},
			itemsCaliber: [],
			maxNumberGirdle:0,
			ItemsNumberGirdle:[]
		}),

		created() {
			this.intitialize();
		},

		watch: {
		},

		methods: {
			removeCaliberPallet(item){

				if (item.LppQuantityJaba > 0) {
					this.$fun.alert("La parihuela ya tiene jabas.", "error");
					return;
				}
				item.SecStatus = 0;

				this.$fun.alert("¿Esta seguro de eliminar la parihuela?", "question")
				.then((r)=> {
					if (r.value) {
						_sCreatePalletTempService.save(item, this.$fun.getUserID())
						.then((r) => {
							if (r.status) {
								this.$fun.alert("Se agrego correctamente", "success");

								this.intitialize();
							}
						});
					}
				});
				
			},
			addCaliberPallet(){

				if(this.itemPallet.CaliberObj == null){
					this.$fun.alert("No se ha selecionado un Calibre", "warning");
					return;
				}
				if(this.itemPallet.CaliberObj.TypePalletName == null){
					this.$fun.alert("No se ha selecionado un Calibre", "warning");
					return;
				}
			 
				if(this.itemPallet.NumberGirdle <= 0 || this.itemPallet.NumberGirdle == null ){
					this.$fun.alert("No se ha ingresado numero de Faja", "warning");
					return;
				}

				if(this.itemPallet.NumberGirdle > this.maxNumberGirdle){
					this.$fun.alert("El numero de Faja no es valido", "warning");
					return;
				}
 
				this.itemPallet.TypeCultive = this.itemPallet.CaliberObj.TypeCultive;
				this.itemPallet.TypeCultiveName = this.itemPallet.CaliberObj.TypeCultiveName;	 
				this.itemPallet.TypeCaliber = this.itemPallet.CaliberObj.TypePallet;
				this.itemPallet.TypeCaliberName = this.itemPallet.CaliberObj.TypePalletName;
				this.itemPallet.CaliberObj = "";

				this.itemPallet.IsRework = 1;
				this.itemPallet.PrsID = (this.$fun.getUserInfo()).PrsID;

				console.log("this.itemPallet", this.itemPallet);
				
				_sCreatePalletTempService.save(this.itemPallet, this.$fun.getUserID())
				.then((r) => {
					if (r.status) {
						this.$fun.alert("Se agrego correctamente", "success");

						this.intitialize();
					}
				});
				//this.itemsPallet.push(this.itemPallet);
			},
			click(val) {
				
                console.log('seleccionamos el tipe crop', val);
				this.TypeCrop = parseInt(val.DedAbbreviation);
				this.TypeAreaGeneric = val.DedValue;

				this.intitialize();
				this.openDialogIndex = false;
			},

			intitialize() {
				
				let obj = this.$fun.getUserInfo();
				console.log("this.obj", obj.PrsID);	
				_sCreatePalletTempService.list({ PrsID: obj.PrsID, IsRework: 1 }, this.$fun.getUserID())
					.then((r) => {
						 console.log("this.itemsCaliber", r.data);
						if (r.status == 200) {
							this.itemsPallet = r.data;
							console.log();
						}
					});
				//cambio samir
				 _sLotProdCalibratedService
					.GetPalletDed(obj.PrsID, this.TypeCrop, this.$fun.getUserID())
					.then(r => {
						//this.itemsPallet = r.data;
						this.itemsCaliber = r.data;
						 console.log("this.itemsCaliber", this.itemsCaliber);
						if(this.itemsPallet.length > 0 ){
							this.TypeAreaAssignPerson = r.data[0].TypeArea;
						}
						 
					}); 

			    _sLotProdCalibratedService
					.GetPalletSend({
                        PrsID: this.$fun.getUserInfo().PrsID, 
                        TypeCrop: this.TypeCrop,
                        IsRework: 1
                        }, this.$fun.getUserID())
					.then(r => {
						this.itemsSend = r.data;
                        console.log('Pallet del paginado',this.itemsSend)
						if(this.executeQR){
							if(this.itemsSend.length > 0){
								let ob = this.itemsSend[0];
								this.selectedItemsSend = [ob];
								this.rowClickSend(ob, ob);

								if(this.selectedItemsSend.length > 0) {

									_sLotProdCalibratedPrintQRService.GetDetailQR(this.selectedItemsSend[0].LlpID, this.$fun.getUserID()).then(resp => {
										if (resp.status == 200) {
											this.itemsSendDetail = resp.data;
											
											this.WbbNetWeight = 0;
											this.itemsSendDetail.forEach(element => {
												this.WbbNetWeight += element.WbbNetWeight
											});

											this.printQR();
											console.log("item seleccionado", ob);
											this.executeQR = false;	
										}
									});
								}	
							}
						}
					});	

			},

			openModal(item) {
				
				console.log("aaaaaaa", item);
				this.PalletNro = item.TypePallet;
				this.typeCaliber = item.TypePallet;
				this.itemPalletOne  = item;

				
				

				this.TypeCultive = item.TypeCultive;
				this.openDialog = true;
			},

			close() {
				this.openDialog = false;
				this.executeQR = false
				this.intitialize();
			},

			closeTransfer(){
				this.intitialize();
				this.openTranfer = false;
			},

			rowClickSend: function(item, row) {
				this.selectedItemsSend = [item];
				

					if(this.selectedItemsSend.length > 0) {

						_sLotProdCalibratedPrintQRService.GetDetailQR(this.selectedItemsSend[0].LlpID, this.$fun.getUserID()).then(resp => {
							if (resp.status == 200) {
								this.itemsSendDetail = resp.data;
								
								this.WbbNetWeight = 0;
								this.itemsSendDetail.forEach(element => {
									this.WbbNetWeight += element.WbbNetWeight
								});
								
							}
						});
					}

			},

			clickSend(val) {
				//console.log("antes de guardar ", val);
				if (val.TypeDestiny == null) {
					this.$fun.alert("Seleccione un destino", "warning");
					return;
				}

				
				if (val.TypeDestiny == 2) {
					if (val.ScfId == undefined) {
						this.$fun.alert("Seleccione una Tarima", "warning");
						return;
					}
				}
				
				//samir
				//return;

				if (val.LppQuantityJaba == 0) {
					this.$fun.alert("Pallet no se encuentra abastecido", "warning");
					return;
				}

				let obj = this.$fun.getUserInfo();

				val.xxTypeCrop = this.TypeCrop
				val.PrsID =  obj.PrsID,
				val.SecStatus = 1;
				val.UsrCreateID = this.$fun.getUserID();


				this.$fun
					.alert("Esta de seguro de enviar Pallet ?", "question")
					.then(res => {
						if (res.value) {
							_sLotProdCalibratedService
								.send(val, this.$fun.getUserID())
								.then(r => {
									if (r.status == 200) {
										this.$fun.alert(
											"Pallet enviado con exito",
											"success",
										);

										//samir
										ServiceScaffold.list(this.$fun.getUserID())
										.then((r) => {
											if(r.data != null){
												this.Scaffolds = r.data;
												
											}
										});

										this.executeQR = true
										this.intitialize();
										
									}
								});
						}
					});
			},

			clickTranfer(val) {
				this.itemsTranfer = val;
				this.openTranfer = true;
			},

			deleteParihuela(val){
				this.$fun
					.alert("Esta de seguro de eliminar Pallet ?", "question")
					.then(res => {
						if (res.value) {
							_sLotProdCalibratedService
								.send(val, this.$fun.getUserID())
								.then(r => {
									if (r.status == 200) {
										this.$fun.alert(
											"Pallet elimiado con exito",
											"success",
										);
										this.intitialize();
										
									}
								});
						}
					});
			},



			printQR() {
				
				if(this.itemsSend.length > 0) {

				let ScfCode = "";
				if (this.selectedItemsSend[0].ScfCode != "") {
					ScfCode = "<h2 style='margin: 0px'><b>Tarima: " + this.selectedItemsSend[0].ScfCode + " || REPROCESO</b></h2>";
				} else {

					ScfCode = "<h2 style='margin: 0px'><b>REPROCESO</b></h2>";
				}



				console.log("this.selectedItemsSend[0] sa", this.selectedItemsSend[0]);

				var imgAll = document.querySelector("img");

				var Pagelink = "about:blank";
				var pwa = window.open(Pagelink, "_new");
				pwa.document.open();
				pwa.document.write(
					"<html><head><scri" +
						"pt>function step1(){\n" +
						"setTimeout('step2()', 10);}\n" +
						"function step2(){window.print();window.close()}\n" +
						"</scri" +
						"pt><style media='print'>  @media print  { @page { margin-left: 4cm; {} </style></head><body onload='step1()'> \n" +
						"<div class='col col-12 justify-center'>",
				);

				
				/* imgAll.forEach(element => { */
					pwa.document.write(
						"<div class='col col-4' style='margin: 20px 20px 20px 20px;'><img alt='Scan me!' style='display: block;'  src='" +
							imgAll.currentSrc +
							"' /></div>",
					);

					pwa.document.write("<br><br><div ><table border='0'>");
					pwa.document.write(
								"<tr><td colspan='4'>Datos Generales: " + 
								"<tr>" +
								"<td>" + "<b>"+this.selectedItemsSend[0].LlpID + "</b>"+
								"</td><td> || " + "<b>"+this.selectedItemsSend[0].TypeCultiveName + "</b>"+
								"</td><td> || " + "<b>"+this.selectedItemsSend[0].VrtName + "</b>"+
								"</td><td> || " + "<b>"+this.selectedItemsSend[0].TypeCropName + "</b>"+
								"</td><td> || " + "<b>"+this.selectedItemsSend[0].TypeDestinyName + "</b>"+
								"</td><td> || " + "<b>"+this.selectedItemsSend[0].TypeCaliberName + "</b>"+
								/* "</td><td> || " + "<b>" + this.WbbNetWeight.toFixed(2)+"</b>"+ */
								"</td></tr><tr><td colspan='5'>" + this.selectedItemsSend[0].TypeCaliberName + "</td></tr>" +
								" </table></div>"  + ScfCode 
								+ "<span class='h1' style='font-size: large;'><b>" + this.selectedItemsSend[0].TypeBsnLineName + "</b></span>"
					);
					// pwa.document.write("" + ScfCode);

					this.itemsSendDetail.forEach(detail => {
							
							pwa.document.write("<br><br><div ><table border='0'>");

							pwa.document.write(
								"<tr ><td colspan='3'>Lote Produccion: " + detail.RcfID + "-" +  detail.RcfCode + ", Jaba: " + detail.LppQuantityJaba  +", Kg: " + detail.WbbNetWeight.toFixed(2) +"</td></tr>"
							);

							pwa.document.write(
								"<tr><td>Productor: </td><td>" + detail.PrdCardName +"</td></tr>" +
								"<tr><td>Fundo: </td><td>" + detail.FagName + " || "+ detail.FltName + " || "+ detail.FltCodSenasa + "</td></tr>" + 
								"<tr><td>Procedencia: </td><td>" + detail.ZonName + " - " + detail.TypeZonLocationName + "</td></tr>"
							);

							pwa.document.write(
								"<tr><td>Certificaciones: </td><td>" + detail.TcDescription +"</td></tr>",
							);
							pwa.document.write(
								"<tr><td>Posición: </td><td>" + detail.LppPosition +"</td></tr>",
							);
							

							pwa.document.write("</table></div>");
						});

				/* }); */

				

				pwa.document.write("</div></body></html>");
				pwa.document.close();

				}
			},

			view(){
				this.openViewQR = true
			}

		},

		mounted() {
			let recaptchaScript = document.createElement("script");
			recaptchaScript.setAttribute("src", this.$const.URL + "/signalr/hubs");
			document.head.appendChild(recaptchaScript);

			recaptchaScript.addEventListener("load", () => {
				$.connection.hub.url = this.$const.URL + "/signalr";
				var chat = $.connection.notification;
				chat.client.SendMessageSendParihuela = group => {
					this.intitialize();
				};

				$.connection.hub.start({ jsonp: true }).done(e => {
					chat.invoke("addToGroup", "updateWaitingsendparihuela");
				});
			});

			ServiceScaffold.list(this.$fun.getUserID())
			.then((r) => {
				if(r.data != null){
					this.Scaffolds = r.data;
					 
				}
			});

			 _sGenParam.search({ PrmName: "maxNumberGirdle" }, this.$fun.getUserID())
				.then((resp) => {
					if (resp.status == 200) {
						this.maxNumberGirdle = parseInt(resp.data.PrmValue);

						 
						for (let index = 1; index <= this.maxNumberGirdle; index++) {
							this.ItemsNumberGirdle.push(index);							
						}	
						console.log(this.ItemsNumberGirdle);
					}
				});
		},
	};
</script>
