import Service  from "../Service";

const resource = "rework/";

export default  {

    pagination(parameters, requestID){
        return Service.post(resource+ "pagination", parameters,{
            params: {requestID: requestID},
        });
    },
};